import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import './Project-card.css';
import blenderImage from './media/blender.png'
import React, { useState, useEffect } from 'react';

const yaml = require('js-yaml');
const TITLE = "Programmer 😎";


function HorizontalList (props) {
    if (props.items === undefined) return;
    return (
        <div><b>{props.items.join(" · ")}</b></div>
    );
}

function requireMedia(path) {
    const _requireMedia = require.context('./media', true);
    if (path === undefined) {
        return "";
    }
    else {
        return _requireMedia("./" + path);
    }
}

function getProjectImageClickHandler(getter, setter) {
    return function (event) {
        if (getter) {
            setter(undefined);
        }
        const clickedImageUrl = event.target.src;
        setter(clickedImageUrl);
    }
}


class ProjectCard extends React.Component {
    render() {
        let image_elems =
            this.props.images !== undefined ?
                this.props.images.map((image) => {
                    return (
                        <li 
                            className="Project-card-image" 
                            key={image.src}
                        >
                            <img
                                src={requireMedia(image.src)}
                                alt={image.alt}
                                onClick={this.props.imageClickHandler}
                            />
                    </li>)
                })
            :
                undefined;

        let logo = this.props.logo !== undefined
            ?  (<img className="Project-card-logo"
                src={requireMedia(this.props.logo)}
                alt={this.props.title}
            />)
            : undefined;

        return (
            <div className="Project-card">
                <div className="Project-card-top">
                    <a className="Project-card-link" href={this.props.link}>
                        {logo}
                    </a>
                    <div className="Project-card-title-desc">
                        <a className="Project-card-link" href={this.props.link}>
                            <h1 className="Project-card-title">{this.props.title}</h1>
                        </a>
                        <p className="Project-card-desc">{this.props.desc}</p>
                        <div className="Project-card-lists">
                            <HorizontalList className="Project-card-tools" items={this.props.tools}/>
                            <HorizontalList className="Project-card-skills" items={this.props.skills}/>
                        </div>
                    </div>
                </div>
                <ul className="Project-card-images" alt="Screenshots of project">
                    {image_elems}
                </ul>
            </div>
        );
    }
}


function App({}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        // Function to load and parse the YAML file
        const fetchData = async () => {
            try {
                const response = await fetch('data/data.yaml');
                const yamlData = await response.text();
                const data = yaml.load(yamlData);
                setData(data);
            } catch (error) {
                console.error('Error loading YAML data:', error);
            }
        };
        fetchData();
    }, []);

    // Get document, or throw exception on error
    if (!data) return (
        <img id="loading-image" src="media/me_nice_cropped.jpg"/>
    );
    const description = data["description"];
    const description_mobile = data["description-mobile"];
    const projectData = data["projects"]

    let zoomedImageStyle = {
        display: "block" ? selectedImage : "none",
    }

    let projectCards = [];
    for (let proj of projectData) {
        projectCards.push(
            <li className="list-group-item" id="Project-carousel">
                <ProjectCard
                    title={proj.title}
                    link={proj.link}
                    logo={proj.logo}
                    desc={proj.desc}
                    tools={proj.tools}
                    skills={proj.skills}
                    images={proj.images}
                    imageClickHandler={
                        getProjectImageClickHandler(selectedImage, 
                                                    setSelectedImage)}
                />
            </li>
        )
    }

    return (
        <div>
            <img 
                id="zoom-image"
                src={selectedImage}
                style={zoomedImageStyle}
                onClick={() => setSelectedImage(undefined)}
            />
            <div className="grid" id="App">
                <div className="item" id="Side-bar">
                    <div className="Contact-card">
                        <div className="Contact-card-padding" id="Image-and-titles" className="container-grid">
                            <img id="Image-me" src="https://stevenlaczko.com/media/me_nice_cropped.jpg" alt="Steven Laczko"/>
                            <div id="Image-me-shadow"/>
                            <div className="Contact-card-padding" id="Name-title-wrapper">
                                <div id="Name">Steven Laczko</div>
                                <div id="Title">Software Developer</div>
                            </div>
                        </div>
                        <div className="Description Contact-card-padding">
                            {description}
                        </div>
                        <div className="Contact-info">
                            <div className="Contact-me">CONTACT ME</div>
                            <div>
                                {/*<img src={phoneClipart} alt='Phone clip art'/>*/}
                                410-424-5367
                            </div>
                            <div>
                                {/*<img src={letterClipart} alt='Envelope clip art'/>*/}
                                stevendlaczko@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="Projects-desk Scrollable">
                    {projectCards}
                </ul>

                <div id="Padding"/>
                <div className="Footer">
                    <a href="https://www.blender.org/"><img id="art-icon"
                        src={blenderImage} alt="a blender"/></a>
                </div>
            </div>
        </div>
    );
}

export default App;
